import { getDefaultOptions } from 'API/auth'
import { updateGroup } from 'API/course'
import { getUrl } from 'API/getUrl'
import { handleApi } from 'API/handleApiCall'
import { getUserByGroup } from 'API/user'
import axios from 'axios'
import { navigate, PageProps } from 'gatsby'
import { Button } from 'react-bootstrap'
import React, { Fragment, useEffect } from 'react'
import { useState } from 'react'
import { UserType } from 'types/UserAdditionMetaData'
import { exportToCsv } from '../course/[id]'
import { useAppDispatch } from 'redux/hooks'
import { deleteSingleUserFromGroup } from 'API/group'
import ApplicationState, { ApplicationStateActions } from 'redux/slices/ApplicationState'

export default function Group(props: PageProps) {
	const dispatch = useAppDispatch()
	const [id, setId] = useState<string>()
	const [users, setUsers] = useState<UserType[]>()
	const [name, setName] = useState<string>('')
	useEffect(() => {
		if (props.params.id) {
			setId(props.params.id)
			setName(props.params.id)
		}
	}, [])

	const downloadData = async () => {
		console.log(users)
		try {
			if (users) {
				const headers = ['first_name', 'last_name', 'username', 'email']

				const csv_values = users.map((elem) => {
					const row: any = []
					headers.forEach((header) => {
						row.push((elem as any)?.[header] ?? undefined)
					})
					return row
				})
				const arr = [headers, ...csv_values]
				console.log(arr)
				exportToCsv(`${name}_${new Date().toLocaleDateString()}`, arr)
			}
		} catch (error) {
			console.log(error)
		}
	}
	// const downloadData = async () => {
	// 	setLoading(true)
	// 	try {
	// 		if (course?.uid) {
	// 			const activity = await getActivityLogCourse({
	// 				course_id: course?.uid,
	// 			})
	// 			const headers = [
	// 				'user',
	// 				'username',
	// 				'first_opened_at',
	// 				'last_opened_at',
	// 				'submitted_at',
	// 				'count',
	// 				'uid',
	// 				'asset_name',
	// 				'asset_id',
	// 				'asset_type',
	// 			]
	// 			if (activity) {
	// 				const updated_activities = activity.map((elem) => {
	// 					return {
	// 						...elem,
	// 						user: elem.user?.first_name + ' ' + elem.user?.last_name,
	// 						username: elem.user?.username,
	// 						uid: elem.user?.uid,
	// 						asset_name: elem.asset?.name,
	// 						asset_id: elem.asset?.uid,
	// 						asset_type: (RepoMap as any)[elem.asset?.__t],
	// 					}
	// 				})
	// 				const csv_values = updated_activities.map((elem) => {
	// 					const row: any = []
	// 					headers.forEach((header) => {
	// 						row.push((elem as any)?.[header] ?? undefined)
	// 					})
	// 					return row
	// 				})
	// 				const arr = [headers, ...csv_values]
	// 				exportToCsv(`${course.name}_${new Date().toLocaleString()}`, arr)
	// 			}
	// 		}
	// 	} catch (err) {
	// 		console.error(err)
	// 	}
	// 	setLoading(false)
	// }

	useEffect(() => {
		const run = async () => {
			if (id) {
				const users = await getUserByGroup(id)
				if (users) {
					setUsers(users.filter((user) => user.user !== null).map((user) => user.user))
				}
			}
		}

		run()
	}, [id])

	return (
		<Fragment>
			<form
				onSubmit={async (e) => {
					e.preventDefault()
					if (id && id !== name) {
						await updateGroup({ name: id, new_name: name })
						navigate('/admin/group/' + name)
					}
				}}
			>
				<input
					type='text'
					name='name'
					id=''
					required
					value={name}
					onChange={(e) => {
						setName(e.target.value)
					}}
				/>
				<input className='btn btn-primary' type='submit' value='Change Name' />
			</form>
			<div
				onClick={async () => {
					if (window?.confirm("Delete batch and all it's users?")) {
						if (window?.confirm("Are you sure you want to delete batch and all it's users? This action cannot be reversed.")) {
							await handleApi(axios.delete(getUrl('user/batch/?name=') + id, { ...getDefaultOptions() }))
							navigate('/admin/group')
						}
					}
				}}
				className='btn btn-danger'
			>
				Delete
			</div>
			<Button onClick={() => navigate(`/admin/add/group/${id}/student`)} variant='primary'>
				Add Student
			</Button>
			<Button onClick={downloadData} variant='primary'>
				Download CSV
			</Button>
			<table className='table'>
				<thead>
					<tr>
						<th scope='col'>#</th>
						<th scope='col'>First Name</th>
						<th scope='col'>Last Name</th>
						<th scope='col'>Username</th>
						<th scope='col'>Email</th>
						<th scope='col'>Action</th>
					</tr>
				</thead>
				<tbody>
					{users?.map((elem, index) => {
						return (
							<>
								<tr>
									<th scope='row'>{index + 1}</th>
									<td>{elem?.first_name}</td>
									<td>{elem?.last_name}</td>
									<td>{elem?.username}</td>
									<td>{elem?.email}</td>
									<td>
										<div
											onClick={async () => {
												if (window.confirm('Are you sure you want to remove and delete student?')) {
													// await removeTeacher(elem.user.uid, course.uid)
													await deleteSingleUserFromGroup(name, elem.uid)
													dispatch(ApplicationStateActions.setUpdate())
													window?.location?.reload()
													// dispatch(ApplicationStateActions.setUpdate())
												}
											}}
											className='btn btn-danger'
										>
											Remove Student from Group
										</div>
									</td>
								</tr>
							</>
						)
					})}
				</tbody>
			</table>
		</Fragment>
	)
}
